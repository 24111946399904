import { Input } from "antd";
import React, { Suspense } from "react";
import { Route, MemoryRouter as Router, Routes } from "react-router-dom";
// import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageLoading from "./components/spinners/PageLoading";

const Wrapper = React.lazy(() => import("./pages/auth/Wrapper"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPasswordOtp = React.lazy(
  () => import("./pages/auth/ResetPasswordOtp")
);
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const Attendance = React.lazy(() => import("./pages/attendance/Attendance"));
const PaymentHistory = React.lazy(
  () => import("./pages/payment/PaymentHistory")
);

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Router>
        <Routes>
          <Route path="/" element={<Wrapper />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPasswordOtp />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/payment-history" element={<PaymentHistory />} />
          <Route path="/attendance" element={<Attendance />} />
        </Routes>
      </Router>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
